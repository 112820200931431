import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: [],
    countObjects: 0,
    isObjectLoading: null,
    isObjectLoadingId: null,
    loadId: null,
    active: null,
    poly: null,
    save: null,
    mapObjects: [],
    isMapObjectLoading: null,
    countSearchObjects: 0,
    user: null,
    userAccesses: {
      access: {
        role: null
      }
    },
    mainMap: null,
    pattern: null,
    type: null,
    serviceCompanyId: null
  },
  mutations: {
    setFilters(state, { pattern, type, serviceCompanyId }) {
      state.pattern = pattern;
      state.type = type;
      state.serviceCompanyId = serviceCompanyId;
    },
    setUserAccesses(state, user) {
      state.userAccesses = user;
      state.user = user.user;
    },
    setMainMap(state, map) {
      state.mainMap = map;
    },
    countObjects(state, countObjects) {
      state.countObjects = countObjects;
    },
    countSearchObjects(state, countSearchObjects) {
      state.countSearchObjects = countSearchObjects;
    },
    setObjects(state, objects) {
      state.objects.push(...objects);
      const merge = (key, arr) => Object.values(arr.reduce((acc, n) => (
        Object.assign(acc[n[key]] ??= {}, n),
          acc
      ), {}));
      state.objects = merge('id', state.objects);
    },
    resetObjects(state) {
      state.objects = [];
    },
    setMapObjects(state, objects) {
      const merge = (key, arr) => Object.values(arr.reduce((acc, n) => (
        Object.assign(acc[n[key]] ??= {}, n),
          acc
      ), {}));
      state.mapObjects = merge('id', objects);
      state.countObjects = state.mapObjects.length;
    },
    setLoadId(state, objects) {
      state.loadId = objects;
    },
    setActive(state, objects) {
      state.active = objects;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    },
    setMapObjectLoading(state, status) {
      state.isMapObjectLoading = status;
    },
    setObjectLoadingId(state, status) {
      state.isObjectLoadingId = status;
    },
    setCoors(state, objects) {
      state.coors = objects;
    },
    setCitizes(state, objects) {
      state.citizes = objects;
    },
    setCovid(state, objects) {
      state.covid = objects;
    },
    setCovidCancel(state, objects) {
      state.covidCancel = objects;
    },
    setPoly(state, objects) {
      state.poly = objects;
    },
    setSave(state, objects) {
      state.save = objects;
    }
  },
  getters: {
    userAccesses(state) {
      return state.userAccesses;
    },
    userIsRoot(state) {
      return state.userAccesses.access.role === 'ADMIN';
    },
    getLoadId: () => state.objects,
    getActive: () => state.objects,
    getCoors: () => state.objects,
    getCovid: () => state.objects,
    getCovidCancel: () => state.objects,
    getPoly: () => state.objects,
    getSave: () => state.objects
  },
  actions: {
    async getUserAccesses(ctx, token) {
      const res = await api.getUserAccesses(token);
      ctx.commit('setUserAccesses', res);
      // console.log('userInfo', res, ctx.state.user);
    },

    async loadId(ctx, { id, token }) {
      ctx.commit('setObjectLoadingId', true);
      const res = await api.getLoadId({ id, token });
      ctx.commit('setLoadId', res);
      ctx.commit('setObjectLoadingId', false);
      return res;
    },
    async loadObjects(ctx, typeId = 0) { // не актуален
      ctx.commit('setObjectLoading', true);
      const res = await api.getList({
        readOnly: 1,
        type: typeId,
        currentMainMenuNumber: 5
      });
      ctx.commit('setObjects', res);//
      ctx.commit('setObjectLoading', false);
    },
    async loadMapObjects(ctx, payload) {
      ctx.commit('setMapObjectLoading', true);
      const res = await api.getList({
        coords: payload.coords,
        readOnly: 1,
        type: payload.typeId,
        currentMainMenuNumber: 5,
        search: payload.search,
        serviceCompanyId: payload.serviceCompanyId
      });
      ctx.commit('setMapObjects', res);
      ctx.commit('setMapObjectLoading', false);
    },
    async loadActive(ctx, item) {
      const res = await api.getActive({ active: item.active }, item.id);
      ctx.commit('setActive', res);

      return res;
    },
    async loadCoors(ctx, item) {
      const res = await api.getCoors(item.active, item.id);
      ctx.commit('setCoors', res.types);
    },
    async saveCitizes(ctx, item) {
      const res = await api.getCitizes(item);
      ctx.commit('setCitizes', res);
    },
    async loadCovid(ctx, item) {
      const res = await api.getCovid(item.active, item.id);
      ctx.commit('setCovid', res.types);
    },
    async loadCovidCancel(ctx, item) {
      const res = await api.getCovidCancel(item.active, item.id);
      ctx.commit('setCovidCancel', res.types);
    },
    async loadPoly(ctx, item) {
      const res = await api.getPoly(item.active);
      ctx.commit('setPoly', res.types);

      return res;
    },
    async loadSave(ctx, item) {
      const res = await api.getSave(item);
      ctx.commit('setPoly', res.types);

      return res;
    },
    async operateHouses(ctx, payload) {
      const res = await api.operateHouses(payload);
      return res;
    },
    async getHouses(ctx, payload) {
      const res = await api.getHouses(payload);
      return res;
    }
  },
  modules: {}
});
