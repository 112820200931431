<template>
  <div class="message_list">
    <transition name="slide-fade">
      <div
        class="message slide-fade-enter-to"
        :class="messageType"
        v-if="isShow"
      >
        <r-button-action
          class="close opacity-72"
          transparent
          @click="isShow = false"
          icon="close"
          title=""
        />
        <r-icon
          v-if="messageType === 'error'"
          icon="close"
          size="32"
          fill="fargo"
        />
        <r-icon
          v-if="messageType === 'warning'"
          icon="warning"
          size="32"
          fill="lebowski"
        />
        <r-icon
          v-if="messageType === 'success'"
          icon="selected"
          size="32"
          fill="matrix"
        />
        <p class="mt-3 sulguni">
          {{ message }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Message',
  data() {
    return {
      isShow: false,
      message: '',
      messageType: 'default', // default, warning, error, success
      componentsList: []
    };
  },
  methods: {
    showMessage(message, type) {
      this.message = message;
      this.messageType = type;
      this.isShow = true;

      setTimeout(() => {
        this.isShow = false;
        this.message = '';
        this.messageType = 'default';
      }, 10000);
    }

  }

};
</script>

<style scoped lang="scss">

.message_list{
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 20px;
  pointer-events: none;
}
.message {
  pointer-events: all;
  border-radius: 16px;
  padding: 24px;
  max-width: 400px;
  box-shadow: 0 8px 24px rgb(19 57 134 / 16%);
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

  &.error {
    color: var(--rir-fargo)
  }

  &.warning {
    color: var(--rir-lebowski)
  }

  &.success {
    color: var(--rir-matrix)
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20%);
  opacity: 0;
}

</style>
