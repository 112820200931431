import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import rir from '@/plugins/RirLib';
import App from './App.vue';
import router from './router';
import store from './store';
// import 'windi.css';
import YmapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd.js';

const mapSettings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

Vue.prototype.$markerIcon = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON
};

Vue.prototype.$markerIconCovid = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON_COVID
};
const S3_COMMON = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common/vue`
  : process.env.VUE_APP_S3_COMMON_PATH;
Vue.prototype.$S3_COMMON = S3_COMMON;
Vue.prototype.$cityCenter = Array.isArray(window?.__CONFIG__?.cityCenter?.latLng)
  ? [...window?.__CONFIG__?.cityCenter?.latLng] // .reverse()
  : process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);
Vue.use(YmapPlugin, mapSettings);

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions
          // for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
