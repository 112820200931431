import axios from 'axios';
import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getActive(payload, id) {
    const { data } = await this._axios.put(`/cityProperty/rest/service/active/${id}`, payload);
    return data;
  }

  async getLoadId({ id }) {
    const { data } = await this._axios.get(`/cityProperty/rest/service/${id}`);
    return data;
  }

  async getList(payload) {
    const noCache = Math.floor(Math.random(1) * 1000);
    // const { data } = await this._axios.post(`ajax.php?action=getPlaces`, payload);
    // const { data } = await this._axios.post(`ajax.php?action=getPlaces&r=${noCache}`, payload);
    const endpoint = '/cityProperty/rest/service?all=true';
    const sortTypes = {
      2: 'Жилой',
      3: 'Многоквартирный',
      4: 'Дома блокированной застройки'
    };
    if (payload?.coords) {
      const { data } = await this._axios.get('/cityProperty/rest/service/search', {
        params: {
          latLU: 0,
          latRD: 90,
          lonLU: 0,
          lonRD: 90,
          buildingType: sortTypes[payload.type] || null,
          search: payload.search,
          serviceCompanyId: payload.serviceCompanyId
        }
      });
      return data;
    }
    const { data } = await this._axios.get(endpoint, payload);
    return data;
  }

  async getCoors(payload, id) {
    const { data } = await this._axios.put(`/cityProperty/rest/service/coord/${id}`, payload);
    return data;
  }

  async getCitizes(payload, id) {
    const { data } = await this._axios.put('/cityProperty/rest/service/update_citizens', payload);
    return data;
  }

  async getCovid(payload, id) {
    const { data } = await this._axios.post(`/cityProperty/rest/service/covid/${id}/${payload}`);
    return data;
  }

  async getCovidCancel(payload, id) {
    const { data } = await this._axios.delete(`/cityProperty/rest/service/covid/${id}`);
    return data;
  }

  async getPoly(payload) {
    const { data } = await this._axios.post('/cityProperty/rest/service/inPoly', payload);
    return data;
  }

  async getSave(payload) {
    const { data } = await this._axios.get(`/cityProperty/rest/service/change/list?from=${payload.filterStartDate}&to=${payload.filterEndDate}`);
    return data;
  }

  async operateHouses(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateHouses', {
      action: 'updateAttachment',
      ...payload
    });
    return data;
  }

  async getHouses(payload) {
    const { data } = await this._axios.post('/ajax.php?action=houses', {
      attachments: 1,
      ...payload
    });
    return data;
  }

  async getTemplate() {
    const { data } = await this._axios.get('/cityProperty/rest/service/update-template/xls');
    return data;
  }

  async updateHouse(id) {
    const { data } = await this._axios.patch(`/buildingsInfoUpdater/rest/building/${id}/update`);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/GIS_JKH');
    return data;
  }

  async getUK() {
    const { data } = await this._axios.get('/cityProperty/rest/service/company');
    return data;
  }
}
