import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import EditObject from '../views/EditObject.vue';
import changeUK from '../components/changeUK.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  // Админка
  {
    path: '/',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: '',
        component: ObjectList
      },
      {
        name: 'edit',
        path: 'edit/:id',
        component: EditObject,
        meta: {
          reload: true
        }
      },
      {
        name: 'change-uk',
        path: 'change-uk',
        component: changeUK
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

// const CheckUser = (user, next) => {
//   if (user.status <= 0) {
//     // не залогинен
//     window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
//   } else {
//     // залогинен
//     if (!user.access.role === 'ANONYMOUS') {
//       // отправить на главную/ обычный юзер
//       window.location.href = '/';
//     } else {
//       next();
//     }
//   }
// };
router.beforeEach((to, from, next) => {
  next();
//   const user = store.getters.userAccesses;
//   // if (!!user.access) {
//   //   CheckUser(user, next)
//   // } else {
//   store.dispatch('getUserAccesses').then(() => {
//     CheckUser(store.getters.userAccesses, next);
//   });
//   store.dispatch('getUserInfo');
//   // }
});

export default router;
