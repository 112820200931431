<template>
  <div class="objects">
    <h1 class="ricotta mb-7">
      {{ moduleTitle }}
    </h1>
    <upload/>
    <div class="objects__filter">
      <r-select
        :value="1"
        class="flex-1"
        :items="sortTypes"
        label="Тип дома"
        @change="onChangeType"
        v-model="selectedSortTypes"
      />
      <r-select
        class="flex-1 ml-6"
        :items="sortUk"
        label="Управляющие компании"
        v-model="selectedUk"
      />

      <r-input
        class="flex-1 ml-6"
        label="Поиск"
        @input="searchCard"
        v-model="search"
        before-icon="search"
      />
      <!-- потом -->
      <div
        v-show="activeTabId !== 'moderation'"
        class="flex ml-6"
      >
        <r-button-simple
          size="larishae"
          icon="icon-view"
          :type="selectedView === 'map' ? 'secondary' : 'primary'"
          @click="onChangeTable"
        />
        <r-button-simple
          size="larishae"
          v-show="activeTabId !== 'moderation'"
          class="ml-2"
          icon="geopoint"
          :type="selectedView === 'map' ? 'primary' : 'secondary'"
          icon-size="20"
          @click="onChangeMap"
        />
      </div>
    </div>

    <template>
      <div class="mt-6 flex align-items-center">
        <!-- <r-checkbox
          class="mt-1"
          label="Показать скрытые"
          @input="onChangeHide"
        />-->
        <div class="float-right parmigiano text-titanic opacity-48">
          {{ !!search ? 'Найдено' :'Всего' }} {{ !!search ? countSearchObjects : countRow }}
        </div>
      </div>
      <loader v-if="isLoading"/>
      <div v-else>
        <div
          v-show="activeTabId === 'moderation' || selectedView === 'table'"
          :key="componentKeyTable"
        >
          <loadCard
            ref="cards"
            :key="componentKey"
            :check="isCheck"
            :selected-view="selectedView"
            :is-search="isSearch"
            :check-house="isCheckHouse"
            :company="selectedUk"
          />
        </div>

        <div v-if="selectedView === 'map' && activeTabId !== 'moderation'">
          <load-map
            :key="mapkey"
            :check="isCheck"
            ref="map"
            :is-search="isSearch"
            :is-check-house="isCheckHouse"
            :company="selectedUk"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Card from '@/components/ObjectCard.vue';
import _ from 'lodash';
import Upload from '../components/Upload.vue';
import changeUK from '../components/changeUK.vue';
import loadCard from '../components/loadCard.vue';
import LoadMap from '../components/Map.vue';
import Loader from '../components/Loader';
import PlaceApi from '../api/PlaceApi';

export default {
  name: 'ObjectList',
  components: {
    Card,
    Upload,
    changeUK,
    loadCard,
    LoadMap,
    Loader
  },
  data() {
    return {
      mapkey: 1,
      file: String,
      sortTypes: [
        {
          id: 1,
          title: 'Все'
        },
        {
          id: 2,
          title: 'Жилой'
        },
        {
          id: 3,
          title: 'Многоквартирный'
        },
        {
          id: 4,
          title: 'Дома блокированной застройки'
        }
      ],
      selectedSortTypes: 1,
      activeTabId: 'published',
      selectedView: 'table',
      countOnPublic: null,
      countOnModerate: null,
      componentKey: 0,
      isCheck: false,
      isCheckHouse: null,
      isSearch: '',
      search: null,
      componentKeyTable: 0,
      sortUk: [],
      selectedUk: 'ALL'
    };
  },

  computed: {
    moduleTitle() {
      return window.moduleTitle || 'ГИС ЖКХ';
    },
    countRow() {
      return this.$store.state.countObjects;
    },
    countSearchObjects() {
      return this.$store.state.countSearchObjects;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  activated() {
    if (this.selectedView === 'map') {
      this.mapkey++;
      this.removeAll();
      this.$store.dispatch('loadMapObjects', {
        typeId: this.isCheckHouse,
        coords: true,
        search: this.isSearch,
        serviceCompanyId: this.selectedUk !== 'ALL' ? this.selectedUk : null
      });
    }
  },
  created() {
    this.searchCard = _.debounce(this.searchCard, 300);
    this.getUK();
  },
  methods: {
    getUK() {
      new PlaceApi().getUK().then(res => {
        this.sortUk = [
          {
            id: 'ALL',
            title: 'Все'
          },
          ...res
        ];
      });
    },
    // Поск по району
    onChangeMap() {
      this.selectedView = 'map';
      console.log('mapInit');
      // this.$refs.map.markersWasAdd();
      this.componentKey += 1;
    },
    onChangeTable() {
      this.selectedView = 'table';
      console.log('mapInit');
      // this.$refs.map.markersWasAdd();
      this.componentKeyTable += 1;
    },
    searchCard() {
      this.isSearch = this.search ? this.search.toLowerCase() : null;
      if (this.selectedView === 'map') {
        this.removeAll();
        this.$store.dispatch('loadMapObjects', {
          typeId: this.isCheckHouse,
          coords: true,
          search: this.isSearch,
          serviceCompanyId: this.selectedUk !== 'ALL' ? this.selectedUk : null
        });
      }
    },
    // Поск по типу дома
    async onChangeType(id) {
      this.isCheckHouse = id;
      this.setFiltersData();
      if (this.selectedView === 'map') {
        this.removeAll();
        this.$store.dispatch('loadMapObjects', {
          typeId: id,
          coords: true,
          serviceCompanyId: this.selectedUk !== 'ALL' ? this.selectedUk : null
        });
      }
    },
    setFiltersData() {
      this.$store.commit('setFilters', {
        pattern: this.isSearch,
        type: this.isCheckHouse,
        serviceCompanyId: this.selectedUk !== 'ALL' ? this.selectedUk : null
      });
    },
    removeAll() {
      this.$store.commit('setMapObjectLoading', true);
      this.$store.state?.mainMap?.geoObjects.removeAll();
    },
    async onChangeHide() {
      const _this = this;

      _this.isCheck = !_this.isCheck;
      // console.log('12345', _this.isCheck);
      _this.componentKey += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.float-right {
  margin: 0 0 0 auto;
}
.color-card {
  color: #0e1420;
}
</style>
