<template>
  <div class="objects__map mt-6">
    <div
      class="map_loader"
      v-if="$store.state.isMapObjectLoading"
    >
      <r-spinner/>
    </div>
    <rir-map
      :key="key"
      @click="onMapClick"
      @onInit="onMapInit"
      :zoom="15"
      ref="myChild"
      :balloon-component="balloonCard"
      :draw-polygon="true"
    >
      <ymap-marker
        @balloonopen="openBalloon(marker.id)"
        v-for="marker in allObjects"
        :key="marker.id + key"
        :coords="[`${marker.lat}`, `${marker.lon}`]"
        :marker-id="marker.id"
        :covids="marker.covid"
        :balloon="{header: {id: marker.id, name: marker.address}}"
        :properties="{id: marker.id}"
        :options="{
            hideIconOnBalloonOpen: false,
            clusterize: false,
            iconColor: marker.covid ? '#E9A35D' :'#3D75E4',
          }"
        cluster-name="cluster"
        :icon="marker.covid ? $markerIconCovid : {}"
        :balloon-component-props="{
            id: marker.id,
          }"
      />
    </rir-map>
  </div>
</template>

<script>
import RirMap from '@/components/RirMap.vue';
import BalloonCard from '@/components/BalloonCard.vue';

export default {
  name: 'Map',
  components: {
    RirMap
  },
  props: {
    check: {
      type: Boolean,
      default: true
    },
    checkHouse: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    isCheckHouse: {
      type: [String, Number],
      default: null
    }

  },
  data() {
    return {
      mapLoading: false,
      countOnPublic: null,
      countOnModerate: null,
      loadMap: true,
      res: true,
      count: 0,
      index: [],
      districtNumber: null,
      districtType: null,
      institution: {},
      selectedInstitutions: [],
      selectedStaff: [],
      staff: {},
      selectedType: null,
      coords: [],
      isLoading: true,
      yaMapPoint: null,
      yaMapPointEdge: null,
      mapInstance: null,
      isMapReady: false,
      newPolygonIndex: 0,
      geometry: [],
      loadCreate: false,
      BalloonPoligon: [],
      componentKey: false,
      coors: [],
      house: null,
      poligonIs: true,
      value: null,
      initPoligons: true,
      countGis: 0,
      polygonIndex: 0,
      openedBalloonInfo: {},
      key: 1,
      buildingType: {
        2: 'Жилой',
        3: 'Многоквартирный',
        4: 'Дома блокированной застройки'
      }
    };
  },
  computed: {
    balloonCard() {
      return BalloonCard;
    },
    // Загрузка списка домов
    allObjects() {
      this.countOnPublic = 0;
      // console.log('allObjects', this.$store.state.objects);
      this.countOnModerate = this.countOnPublic = null;
      if (!this.$store.state?.mapObjects?.length) return [];

      const objects = this.$store.state.mapObjects;
      this.key++;
      return objects;
    }
  },

  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      if (this.$store.state.mapObjects.length < 1) {
        await this.$store.dispatch('loadMapObjects', {
          typeId: this.isCheckHouse,
          coords: true,
          search: this.isSearch,
          type: this.buildingType[this.checkHouse] || '',
          pattern: this.isSearch,
          serviceCompanyId: this.company !== 'ALL' ? this.company : null
        });
      }
    },
    async onMapInit(e) {
      this.mapInstance = e;
      this.$store.commit('setMainMap', e);
    },
    loadBalloon(index) {
      if (this.BalloonPoligon != null) {
        this.house = this.BalloonPoligon;
        this.index[index] = this.BalloonPoligon;
        this.BalloonPoligon = null;
      }
      this.poligon(index);
    },
    onMapClick(e) {
    },
    linkClick(id) {
      const r = this.$router.resolve({
        name: 'edit',
        params: {id}
      });
      window.location.assign(r.href);
    }
  }
};
</script>

<style scoped>

.objects__map {
  position: relative;
}

.map_loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, .3);
  z-index: 12121;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}


</style>
