var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"objects__map mt-6"},[(_vm.$store.state.isMapObjectLoading)?_c('div',{staticClass:"map_loader"},[_c('r-spinner')],1):_vm._e(),_c('rir-map',{key:_vm.key,ref:"myChild",attrs:{"zoom":15,"balloon-component":_vm.balloonCard,"draw-polygon":true},on:{"click":_vm.onMapClick,"onInit":_vm.onMapInit}},_vm._l((_vm.allObjects),function(marker){return _c('ymap-marker',{key:marker.id + _vm.key,attrs:{"coords":[`${marker.lat}`, `${marker.lon}`],"marker-id":marker.id,"covids":marker.covid,"balloon":{header: {id: marker.id, name: marker.address}},"properties":{id: marker.id},"options":{
          hideIconOnBalloonOpen: false,
          clusterize: false,
          iconColor: marker.covid ? '#E9A35D' :'#3D75E4',
        },"cluster-name":"cluster","icon":marker.covid ? _vm.$markerIconCovid : {},"balloon-component-props":{
          id: marker.id,
        }},on:{"balloonopen":function($event){return _vm.openBalloon(marker.id)}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }