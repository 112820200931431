<template>
  <div>
    <div class="objects__list mt-6">
      <router-link
        v-for="(item, $index) in filteredObjects"
        :key="'k_'+item.id+$index"
        :to="{
          name: 'edit',
          params: { id: item.id },
          query: activeTabId === 'moderation' ? { moderate: true } : null
        }"
      >
        <card
          ref="Card"
          :address="item.address"
          :active="item.active"
          :house="item.buildingType"
          :service-title="item.serviceTitle"
          class="color-card"
        >
          <!-- <div class="flex align-items-center">
             <r-tooltip
               activator-hover
               color="amelie"
             >
               <template #activator>
                 <div class="flex mr-5 ">
                   <r-icon class="mr-2" icon="avatar"/>
                   <span class="briscola color-card">{{ item.population }}</span>
                 </div>
               </template>
               <div class="text-titanic">Прописанные жители</div>
             </r-tooltip>
             <r-tooltip
               activator-hover
               color="amelie"
             >
               <template #activator>
                 <div class="flex mr-5 ">
                   <r-icon class="mr-2" icon="buildings"/>
                   <span class="briscola color-card">{{ item.livingRoom }}</span>
                 </div>
               </template>
               <div class="text-titanic">Жилые помещения</div>
             </r-tooltip>
             <r-tooltip
               activator-hover
               color="amelie"
             >
               <template #activator>
                 <div class="flex ">
                   <r-icon class="mr-2 " icon="public"/>
                   <span class="briscola color-card">{{ item.porch }}</span>
                 </div>
               </template>
               <div class="text-titanic">Подъезды</div>
             </r-tooltip>

           </div>-->
          <div class="flex align-items-center">
            <div
              class="flex mr-5 icon-placeholder"
              data-title="Прописанные жители"
            >
              <r-icon
                size="16"
                class="mr-2"
                icon="avatar"
                fill="rocky"
              />
              <span class="briscola color-card">{{ item.population }}</span>
            </div>
            <div
              class="flex mr-5 icon-placeholder"
              data-title="Жилые помещения"
            >
              <r-icon
                size="16"
                class="mr-2"
                icon="buildings"
                fill="rocky"
              />
              <span class="briscola color-card">{{ item.livingRoom }}</span>
            </div>
            <div
              class="flex icon-placeholder mr-5"
              data-title="Подъезды"
            >
              <r-icon
                size="16"
                class="mr-2 "
                icon="park"
                fill="rocky"
              />
              <span class="briscola color-card">{{ item.porch }}</span>
            </div>
            <div
              class="flex align-items-center"
              v-if="item.covid"
            >
              <r-icon
                size="16"
                class="mr-2"
                icon="virus"
                fill="lebowski"
              />
              <span class="briscola color-card text-lebowski">Covid</span>
            </div>
          </div>
        </card>
      </router-link>
      <infinite-loading
        @infinite="infiniteHandler"
        ref="infinite"
      >
        <div slot="spinner">
          <loader />
        </div>
        <div slot="no-more" />
        <div slot="no-results">
          <div
            class="objects__list--not mt-6"
            v-show="this.$store.state.countSearchObjects < 1 && infiniteLoaded"
          >
            <img src="../assets/not-found.png">
            <span class="feta text-titanic opacity-72 mb-1">К сожалению, ничего не найдено</span>
            <span class="mozzarella text-titanic opacity-48">Попробуйте изменить запрос</span>
          </div>
        </div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import Card from '@/components/ObjectCard.vue';
import infiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import _ from 'lodash';
import loader from './Loader';

export default {
  name: 'LoadCard',
  components: {
    Card,
    infiniteLoading,
    loader
  },
  props: {
    selectedView: {
      type: String
    },
    check: {
      type: Boolean,
      default: false
    },
    checkHouse: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    company: {
      type: [Number, String],
      default: () => {}
    }
  },
  data() {
    return {
      countOnPublic: null,
      countOnModerate: null,
      countGis: 0,
      activeTabId: 'institutions',
      page: 1,
      url: '', /// cityProperty/rest/service/company
      notDefaultUrl: false,
      firstLoad: false,
      infiniteLoaded: false,
      buildingType: {
        2: 'Жилой',
        3: 'Многоквартирный',
        4: 'Дома блокированной застройки'
      }
    };
  },

  computed: {
    // Загрузка списка домов
    allObjects() {
      this.countGis = 0;
      this.countOnPublic = 0;
      // console.log('allObjects', this.$store.state.objects);
      this.countOnModerate = this.countOnPublic = null;
      if (!this.$store.state?.objects?.length) return [];
      let objects = [];
      if (this.$props.selectedView === 'map') {
        objects = this.$store.state.objects.filter(el => el.lat && el.lon);
      } else {
        objects = this.$store.state.objects;
      }

      return objects
        .map(el => {
          if (el?.id) {
            el.active === false ? this.countOnPublic++ : this.countOnModerate++;
            return {
              counts: this.countOnPublic,
              id: el.id,
              active: el.active ? el.active : false,
              buildingType: el.buildingType,
              porch: (el.porch ? String(el.porch) : '0'),
              population: (el.population ? String(el.population.total) : '0'),
              livingRoom: (el.livingRoom ? String(el.livingRoom) : '0'),
              serviceTitle: (el.serviceTitle ? String(el.serviceTitle) : ''),
              address: el.address || '-',
              lat: el.lat,
              lon: el.lon,
              covid: el.covid
            };
          }
        })
        .sort((a, b) => (b.count < 20) && a.address.localeCompare(b.address));
    },
    // Фильтрация списка домов
    filteredObjects() {
      let objects = [];
      this.countGis = 0;

      objects.push(...this.allObjects);
      if (!this.check) {
        objects = objects.filter(
          el => ((el.active === true))
        );
      }
      return objects;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  watch: {
    checkHouse: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        this.resetInfinite();
      }
    },
    isSearch: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        this.resetInfinite();
      }
    },
    company: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        this.resetInfinite();
      }
    }
  },
  methods: {
    infiniteHandler($state) {
      // this.$store.dispatch('loadObjects',{typeId: 0, page: this.page});
      axios.get('/cityProperty/rest/service/page', {
        params: {
          page: this.page,
          itemCount: 50,
          extended: true,
          type: this.buildingType[this.checkHouse] || '',
          pattern: this.isSearch,
          serviceCompanyId: this.company !== 'ALL' ? this.company : null
        },
        headers: {
          authorization: `Bearer ${window.token}`
        }
      }).then(({ data }) => {
        const objects = data.buildings || data;
        if (!this.isSearch) {
          this.$store.commit('countObjects', data?.total);
        } else {
          this.$store.commit('countSearchObjects', data?.total);
        }
        if (objects.length) {
          this.page += 1;
          this.$store.commit('setObjects', objects);

          this.infiniteLoaded = true;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    linkClick(id) {
      const r = this.$router.resolve({
        name: 'edit',
        params: { id }
      });
      window.location.assign(r.href);
    },
    resetInfinite() {
      this.$store.commit('resetObjects');
      this.page = 1;
      this.firstLoad = true;
      this.$nextTick(() => {
        this.$refs.infinite.stateChanger.reset();
      });
    }
  }

};
</script>

<style lang="scss" scoped>

.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    &--not {
      margin-top: 136px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      & > img {
        margin-bottom: 8px;
      }
    }
  }
}

.float-right {
  margin: 0 0 0 auto;
}

.color-card {
  color: #0e1420;
}

.icon-placeholder {
  display: inline-flex; /* Строчно-блочный элемент */
  position: relative;
}

.icon-placeholder:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -10px;
  white-space: nowrap;
  top: calc(100% + 10px); /* Положение подсказки */
  z-index: 1200; /* Отображаем подсказку поверх других элементов */
  background: #FFFFFF;
  border-radius: 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-right: 8px;
  box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
}

.icon-placeholder:hover::before {
  content: '';
  position: absolute;
  left: 2px;
  top: calc(100% + 4px);
  width: 12px;
  height: 6px;
  z-index: 1210;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 0L0 6H12L6 0Z' fill='white'/%3e%3c/svg%3e ");
  background-repeat: no-repeat;
}

::v-deep .infinite-loading-container [class^=loading-] {
  display: flex !important;
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 0 !important;

  .loading-ring__spinner {
    width: 72px !important;
    height: 72px !important;
    border-radius: 50% !important;
  }

  .loading-ring__text {
    margin-top: 12px !important;
  }
}

::v-deep .infinite-loading-container {
  grid-column: 1/3;
}

</style>
