<template>
  <div>
    <router-link
      :to="{
        name: 'edit',
        params: { id: `${id}` },
        query: activeTabId === 'moderation' ? { moderate: true } : null
      }"
    >
      <div class="r-map-balloon">
        <div class="r-map-balloon__info">
          <p class="roquefort">
            {{ details.address || '—' }}
          </p>
          <p class="mt-1 сaprino r-map-balloon__address">
            {{ details.buildingType || '—' }} {{ details.serviceTitle }}
          </p>
          <div class="mt-7">
            <div class="flex align-items-center">
              <div class="flex mr-5">
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="avatar"
                  fill="rocky"
                />
                <span
                  class="briscola"
                  style="color: #0e1420"
                >{{ details.population.total || '0' }}</span>
              </div>
              <div class="flex mr-5">
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="buildings"
                  fill="rocky"
                />
                <span
                  class="briscola"
                  style="color: #0e1420"
                >{{ details.livingRoom || '0' }}</span>
              </div>
              <div class="flex">
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="public"
                  fill="rocky"
                />
                <span
                  class="briscola"
                  style="color: #0e1420"
                >{{ details.porch || '0' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import PlaceApi from '../api/PlaceApi';

export default {
  name: 'BalloonCard',
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      activeTabId: 'published',
      details: {
        address: null,
        house: null,
        serviceTitle: null,
        population: {
          total: null
        },
        livingRoom: null,
        porch: null
      }
    };
  },
  async created() {
    await new PlaceApi().getLoadId({
      id: this.id,
      token: window.token
    }).then(res => {
      this.details = { ...this.details, ...res };
    });

    /* await this.$store.dispatch('loadId', this.id).then(res => {
       this.details = { ...this.details, ...res };
     }); */
  }
};
</script>

<style lang="scss" scoped>

.r-map-balloon {
  width: 308px;
  height: 145px;

  &__img {
    width: 100%;
    height: 122px;
  }

  &__info {
    padding: 16px;
  }

  &__address {
    position: static;
    height: 12px;
    left: 0px;
    right: 91px;
    top: 52px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #04153E;
    opacity: 0.72;
  }
}

.roquefort {
  color: #0e1420;
}

</style>
