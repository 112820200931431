<template>
  <div
    :class="[
      'progress',
      `progress--${size}`,
      { amphiris: size === 'medium' },
      { marynae: size === 'small' },
    ]"
    :data-count="overallCount"
  >
    <svg class="progress-svg" :height="height" :width="width">
      <circle
        class="progress-base"
        :r="radius"
        :cx="width / 2"
        :cy="height / 2"
        fill="transparent"
      ></circle>

      <template v-for="(item, index) in items">
        <circle
          class="progress-line"
          :r="radius"
          :cx="width / 2"
          :cy="height / 2"
          stroke="white"
          :stroke-dashoffset="calcStartPoint(index)"
          :stroke-dasharray="`${calcArea(2)} ${dasharray}`"
          fill="transparent"
          :key="index + 1"
        ></circle>
        <circle
          :r="radius"
          :cx="width / 2"
          :cy="height / 2"
          :stroke="item.color || colors(item)"
          fill="transparent"
          :stroke-dashoffset="calcStartPoint(index)"
          :stroke-dasharray="`${calcArea(2)} ${dasharray}`"
          :key="-index * 1000"
        ></circle>
      </template>
    </svg>
  </div>
</template>

<script>
export default {
  name: "changeUK",
  props: {
    keyEvents: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'medium',
    },
  },

  data() {
    return {
      items: [{"count": 3, "color": "green"}],
      overallCount: 2,
      dasharray: 190,
      radius: 30,
      height: 80,
      width: 80,
    };
  },

  watch: {
    keyEvents: {
      immediate: true,
      deep: true,
      handler(value) {
        this.items = [...this.keyEvents];
        if (value.length === 0) {
          this.overallCount = 0;
          return;
        }

        if (value.length === 1) {
          this.overallCount = value[0].count;
          return;
        }

        this.overallCount = value.reduce((accumulator, currentValue) => {
          return (
            (typeof accumulator === 'number' ? accumulator : accumulator.count) + currentValue.count
          );
        });
      },
    },
  },

  created() {
    this.calcSize();
  },

  methods: {

    calcArea(count) {
      const countPercent = (count / this.overallCount) * 100;
      return (countPercent / 100) * this.dasharray;
    },

    calcStartPoint(index) {
      if (index === 0) return 0;

      const prevItems = [];
      for (let i = 0; i < index; i++) {
        prevItems.push(this.items[i].count);
      }

      let percent = 0;
      prevItems.forEach((item) => (percent -= this.calcArea(item)));

      return percent;
    },

    calcSize() {
      switch (this.size) {
        case 'small':
          this.height = 36;
          this.width = 36;
          this.radius = 14;
          break;
        case 'medium':
          this.height = 52;
          this.width = 52;
          this.radius = 20;
          break;
        case 'large':
          this.height = 66;
          this.width = 66;
          this.radius = 28;
      }

      this.dasharray = 2 * Math.PI * this.radius;
    }
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: attr(data-count);
    position: absolute;
  }

  &--small {
    .progress-svg {
      circle {
        stroke-width: 5.5px;
      }

      .progress-line {
        stroke-width: 10px;
      }
    }
  }

  &--medium {
    .progress-svg {
      circle {
        stroke-width: 7.5px;
      }

      .progress-line {
        stroke-width: 12.5px;
      }
    }
  }
}

.progress-svg {
  transform: rotate(-90deg) rotateX(180deg);

  circle {
    stroke-width: 8px;
    stroke-linecap: round;
    transform-origin: center;
    transition: stroke-dashoffset 0.8s ease, stroke-dasharray 0.8s ease;
  }

  .progress-line {
    stroke-width: 12.5px;
  }
.progress-base {
  stroke: #e6ebf5;
}
}
</style>
