<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="32"
            fill="rocky"
          />
        </div>
        <div class="parent align-items-center">
          <div
            class="container-upload"
            v-if="!load"
          >
            <h1 class="ricotta mb-6">
              Новые данные
            </h1>
            <upload-file
              ref="childComponent"
              @input="loadDocuments"
              @initialFile="initialFile"
              :accept-file="[
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ]"
              :count-file="1"
              upload-url="/uploadfile/index.php"
              :value="file"
              :count-line="1"
              sub-title="или перетяните сюда файл, созданый на основе шаблона"
            />
            <a
              class="flex sulguni mt-6 pointer"
              href="/cityProperty/rest/service/update-template/xls"
              style="color: #3d75e4;"
            >
              <r-icon
                size="16"
                icon="load"
                class="mr-1"
                fill="rocky"
              />
              Скачать шаблон для импорта данных в xls
            </a>
            <div class="flex mt-8">
              <r-button
                class="flex-1"
                @click="uploadFile"
                width="wide"
                title="Загрузить"
                :is-loading="isUpload"
              />
            </div>
          </div>
          <div
            class="container-upload"
            v-if="load"
          >
            <h1 class="ricotta mb-2">
              Смена УК
            </h1>
            <div class="mb-6 font">
              Чтобы скачать информацию о смене УК в домах нужно указать период
            </div>
            <div class="flex align-items-center">
              <r-date-picker
                class="mr-6 flex-1"
                click-close
                label="С"
                v-model="filterStartDate"
                :disabled="false"
              />
              <r-date-picker
                class="flex-1"
                click-close
                label="По"
                v-model="filterEndDate"
                :disabled="false"
              />
            </div>
            <div
              class="flex mt-7"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                class="flex-1"
                :disabled="filterStartDate == null || filterEndDate == null"
                @click="save"
                title="Скачать"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a :href="`/cityProperty/rest/service/csv`" >
      <span
        :class="{
          'ml-6': upload
        }"
        class="flex align-items-center sulguni upload-color justify-content-center"
      >
        <r-icon
          size="16"
          class="mr-2"
          icon="load"
          fill="rocky"
        />
        Скачать список
      </span>
    </a>
    <span
      class="flex align-items-center sulguni ml-6 upload-color justify-content-center"
      @click="uploadContainer"
    >
      <r-icon
        size="16"
        class="mr-2"
        icon="add"
        fill="rocky"
      />
      Загрузить данные
    </span>
    <span
      class="flex align-items-center sulguni ml-6 upload-color justify-content-center"
      @click="loadContainer"
    >
      <r-icon
        size="16"
        class="mr-2"
        icon="mncpl-control"
        fill="rocky"
      />
      Скачать информацию о смене УК
    </span>
    <div
      class="load-change"
      v-show="showUpload"
    >
      <div class="image-load">
        <img src="../../public/map/load.png">
      </div>
      <div
        class="load-text load-text-error"
        v-if="error"
      >
        Ошибка загрузки данных.
        <div class="error-load">
          Проверте файл на соответствие шаблону или повторите попытку чуть позже
        </div>
      </div>
      <div
        class="load-text load-text-end"
        v-else
      >
        Данные успешно загружены.
      </div>
      <div
        class="load-text-right"
        @click="uploadContainer"
      >
        <r-icon
          size="16"
          icon="restore"
          fill="fargo"
          class="float-left"
        />
        <div class="text">
          Восстановить
        </div>
      </div>
    </div>
    <message ref="message" />
  </div>
</template>

<script>
import UploadFile from '@/components/UploadFile.vue';
import Message from './Message';

export default {
  name: 'Upload',
  components: {
    UploadFile,
    Message
  },
  props: ['item'],
  data() {
    return {
      filterStartDate: null,
      filterEndDate: null,
      file: [],
      filesUpload: [],
      load: false,
      upload: false,
      modelEnd: [],
      server: process.env.VUE_APP_API_SERVER,
      fileUpload: false,
      showUpload: false,
      error: false,
      isUpload: false
    };
  },
  methods: {
    save() {
      if ((this.filterStartDate != null) && (this.filterEndDate != null)) {
        window.location.href = `/cityProperty/rest/service/change/list?from=${this.filterStartDate}&to=${this.filterEndDate}`;
      }
    },
    initialFile(file) {
      this.filesUpload.push(file);
    },
    async uploadFile() {
      this.isUpload = true;
      const re = /(?:\.([^.]+))?$/;
      const fileExt = this.filesUpload;
      this.filesCountUpload += 1;

      return new Promise(resolve => {
        const url = '/cityProperty/rest/service/update/xls';
        const formData = new FormData();
        formData.append('file', fileExt[0]);
        formData.append('filename', fileExt[0].name);

        this.disabledUpload = false;
        fetch(url, {
          method: 'PATCH',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${window.token}`
          },
          body: formData
        })
          .then(function (response) {
            const _this = this;
            if (response.status !== 200) {
              throw Error(`error load image - ${response.status}`);
            } else {
              return response.json();
            }
          })
          .then(data => {
            if (!data.error) {
              this.uploadSuccess();
            } else {
              this.uploadError();
              this.$refs.message.showMessage(data.error, 'error');
            }
            this.file = [];
          }).catch(() => {
            this.uploadError();
          })
          .finally(() => {
            if (this.progress >= 100) {
              this.isUpload = false;
              this.file = [];
            }
            resolve();
          });
      });
    },
    loadDocuments(files) {
      if (!files?.length) {
        this.file = [];
        return;
      }
      this.file = this.formattedFiles(files);
    },
    formattedFiles(files) {
      const formattedFiles = [];
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      return formattedFiles;
    },
    uploadSuccess(file) {
      console.log(file);
      this.upload = false;
      this.showUpload = true;
      this.isUpload = false;
      this.file = [];
      window.location.reload();
      setTimeout(this.showUp, 4000);
    },
    uploadError() {
      this.error = true;
      this.upload = false;
      this.showUpload = true;
      this.isUpload = false;
      this.file = [];
      setTimeout(this.showUp, 10000);
    },
    uploadContainer() {
      this.showUpload = false;
      this.load = false;
      this.upload = true;
    },
    showUp() {
      this.showUpload = false;
    },
    loadContainer() {
      this.showUpload = false;
      this.load = true;
      this.upload = true;
    }
  }
};
</script>

<style scoped>


.upload-color {
  color: #3d75e4;
  cursor: pointer;
}

.upload {
  background-color: white;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 49;
  overflow-y: auto;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 50%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.float-left {
  float: left;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

.width-container {
  max-width: 100%;
}

.load-change {
  z-index: 1000;
  position: fixed;
  height: 56px;
  width: 70%;
  left: 30%;
  right: 0%;
  top: calc(100% - 56px);
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(39, 20, 2, 0.12);
  border-radius: 28px;
}

.image-load {
  margin: 12px;

  float: left;
}

.load-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.load-text-end {
  margin-top: 18px;
}

.load-text-error {
  margin-top: 14px;
}

.load-text-right {
  cursor: pointer;
  float: right;
  margin-top: -18px;
  margin-right: 24px;
}

.text {
  float: left;
  margin-left: 7px;
  color: #E14761;
}

.float-left {
  float: left;
}

.error-load {
  position: absolute;
  height: 16px;
  left: 58px;
  top: calc(50% - 16px / 2 + 14px);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #34040F;
  opacity: 0.48;
}


</style>
