<template>
  <div id="app">
    <router-view />
    <r-notification />
  </div>
</template>
<script>
export default {
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
&& document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  async created() {
    await this.$store.dispatch('getUserAccesses').then(() => {
      this.CheckUser(this.$store.getters.userAccesses);
    });
  },
  methods: {
    CheckUser(user) {
      if (user.status <= 0) {
        // не залогинен
        window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
      } else {
        // залогинен
        if (!user.access.role === 'ANONYMOUS') {
          // отправить на главную/ обычный юзер
          window.location.href = '/';
          console.log('отправить на главную, обычный юзер или не залогинненый');
        }
      }
    }
  }
};
</script>
