<template>
  <div class="obj-card">
    <div class="flex-1">
      <div class="obj-card__info">
      <div :class="['obj-card__title sulguni', { 'opacity-32': purgeDeadline }]">

        {{ address }}
        </div>
      </div>
      <div :class="['obj-card__counters flex ' +
       'obj-card__address mozzarella style-marin-house', { 'opacity-32': purgeDeadline }]">
        <div class="float-left mr-2 " style="color: #E14761" v-if="!active">Скрыт</div>
        <div class="float-left mr-4 ">{{ house }}</div>
        <div class="float-left mr-2"> {{ serviceTitle }}</div>
      </div>

      <div class="obj-card__counters flex align-items-center">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    address: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    house: {
      type: String,
      default: ''
    },
    serviceTitle: {
      type: String,
      default: ''
    },
    purgeDeadline: {
      type: [String, null],
      default: null
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: table;
  }

  &__title {
  }

  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 14px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.opacity-32 {
  opacity: 0.32;
}

.style-marin-house {
  margin-top: 6px;
}

float-left {
  float: left;
}

</style>
