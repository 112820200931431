<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div class="parent align-items-center">
          <div class="container-upload">
            <r-icon
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1 class="ricotta mb-4 mt-7">
              Удалить {{ item['buildingType'].toLowerCase() }} дом?
            </h1>
            <div class="mb-8 font">
              Все данные о доме по адресу {{ item.address }} будут удалены.
            </div>
            <div class="mt-2 buttons">
              <r-button
                class="flex-1"
                color="secondary"
                @click.native="$rir.modal.close"
                title="Не удалять"
                width="wide"
              />
              <r-button
                class="flex-1"
                @click.native="Delete"
                title="Да, удалить"
                width="wide"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    item: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    Delete() {
      this.$store.dispatch('loadDelete', this.item).then(result => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.buttons{
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px ){
    grid-template-columns: 1fr;
  }
}

</style>
