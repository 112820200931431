<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <router-link
      class="flex align-items-center sulguni mb-7 print"
      :to="{ name: 'admin' }"
    >
      <r-icon
        size="16"
        class="mr-2"
        icon="arrow-left"
        fill="rocky"
      />
      Назад
    </router-link>
    <r-spinner v-if="isLoading || loadSave" />
    <div v-else>
      <div>
        <h1
          class="ricotta"
          style="overflow: auto;"
          id="printableArea1"
          v-if="items['buildingType'] != null"
        >
          {{ items['buildingType'] }}
          <sup
            class="text-c13 text-fargo"
            v-if="!isButton"
          >Не сохранено</sup>
        </h1>

        <div class="flex align-items-center mt-4">
          <div
            class=" mr-6 flex align-items-center text-titanic opacity-72 text-c16"
            v-if="items['address'] != null"
            id="printableArea2"
          >
            <r-icon
              size="16"
              icon="geopoint"
              fill="titanic"
              class="mr-2"
            />
            {{ items['address'] }}
          </div>
          <div
            class=" mr-6 eye flex align-items-center text-titanic opacity-72 text-c16"
          >
            <r-icon
              size="16"
              icon="calendar"
              fill="titanic"
              class="mr-2"
            />
            {{ items.population.added || 'Нет данных' }}
          </div>
          <div
            class=" mr-6  eye flex align-items-center text-titanic opacity-72 text-c16"
            :class="{'pointer' : this.$store.getters.userIsRoot}"
            @click="updateHouse"
          >
            <r-icon
              size="16"
              icon="update"
              fill="titanic"
              class="mr-2"
              :class="{'spin': updateLoading }"
            />
            {{ items['updated'] || 'Не известно' }}
          </div>
        </div>
        <div class="street-house margin-button">
          <div
            class="float-left mr-6 print flex align-items-center text-c16"
            @click="printDiv('printableArea')"
            style="cursor: pointer;"
          >
            <r-icon
              size="16"
              icon="print"
              class="print mr-6px"
              fill="#3D75E4"
            />
            Печать
          </div>
          <!--<div>
            <div class="float-left mr-6 block pointer eye flex align-items-center text-c16"
                 v-show="isActive" @click="active()">
              <r-icon icon="block" class="block mr-6px" fill="#E14761"/>
              Скрыть
            </div>
            <div class="float-left mr-6 print pointer eye flex align-items-center text-c16"
                 v-show="!isActive" @click="active()">
              <r-icon icon="eye" class="print mr-6px" size="16"/>
              Отобразить
            </div>
          </div>-->
        </div>
      </div>
      <r-spinner v-if="isLoading" />
      <div v-else>
        <div class="grid-cont mt-8">
          <div
            class="block-hiden-too"
            id="printableArea"
          >
            <loadObject
              v-if="isLoad"
              :items="items"
              @home="checkHome"
            />
            <upload-file
              :count-file="5"
              upload-url="/uploadfile/index.php"
              :value="documents"
              @input="loadDocuments"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
              :accept-file="[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/csv'
              ]"
            />
          </div>
          <div class="edit__map">
            <map-inner
              :center="coordinates"
              collapse-btn
              :is-covid="true"
            >
              <ymap-marker
                :coords="coordinates"
                :marker-id="'marker'"
                :options="{
                  hideIconOnBalloonOpen: false,
                  draggable: true
                }"
                :icon="$markerIcon"
                @dragend="onDragMarker"
              />
            </map-inner>
            <!-- <div class="message">
               Чтобы изменить положение на карте, перетащите точку
             </div>-->
            <div class="float-left margin-info">
              <h2 class="taleggio mb-6">
                Дополнительная информация
              </h2>
              <r-checkbox
                class="mt-1 float-left"
                :value="value == '' ? [] : ''"
                title="COVID подъезды"
                @input="funcCheck"
              />
              <div
                class="text-covid"
                v-show="!isCovid"
              >
                <r-input
                  class="flex-1 ml-3"
                  placeholder="Подъезды"
                  @keyup="inputCovid"
                  v-model="value"
                  after-icon="info"
                />
              </div>
            </div>
          </div>
        </div>
        <!--<div class="flex align-items-center custom mt-10 mb-60px pointer fargo-hover" @click="DeleteItem(items)">
          <r-icon icon="delete" fill="fargo"/>
          <div class="text-fargo ml-2 text-c16">
            Удалить {{items['buildingType'].toLowerCase()}} дом
          </div>
        </div>-->
        <div class="block-hiden block-hiden-one">
          <loadObject
            v-if="isLoad"
            :items="items"
          />
        </div>
        <div class="button_container">
          <r-button
            class="flex-1"
            :disabled="isButton"
            width="wide"
            @click.native="OpenSaveModal"
            title="Сохранить"
          />
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import loadObject from '@/components/loadObject.vue';
import MapInner from '../components/MapInner';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';
import UploadFile from '../components/UploadFile';
import PlaceApi from '../api/PlaceApi';

export default {
  components: { MapInner, loadObject, UploadFile },
  props: {},
  data() {
    return {
      isActive: Boolean,
      items: {
        active: null,
        address: null,
        lat: null,
        lon: null,
        updated: null,
        buildingType: null,
        porch: null,
        livingRoom: null,
        serviceTitle: '',
        population: {
          total: 0,
          in: 0,
          out: 0,
          notRegistred: null,
          added: null
        },
        covids: [],
        covid: null
      },
      isLoad: false,
      address: null,
      coordinates: [47.5259125, 42.1899268],
      isButton: true,
      isCovid: Boolean,
      value: '',
      coors: false,
      loadSave: false,
      divMap: true,
      isChanged: false,
      initialData: [],
      flyButton: false,
      total: 0,
      ins: 0,
      out: 0,
      documents: [],
      updateLoading: false
    };
  },
  computed: {
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    }
  },

  watch: {},
  activated() {
    this.setData();
  },
  mounted() {
    this.isLoad = false;
  },
  updated() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
  },
  methods: {
    async updateHouse() {
      if (this.$store.getters.userIsRoot) {
        this.updateLoading = true;
        await new PlaceApi().updateHouse(this.$route.params.id).then(res => {
          console.log(res);
        }).finally(() => {
          window.location.reload();
        });
      } else {
        console.log('user not root');
      }
    },
    async setData() {
      await this.$store.dispatch('loadId', {
        id: this.$route.params.id
      }).then(res => {
        const keys = Object.keys(this.$store.state.loadId);
        this.coordinates = [this.$store.state.loadId.lat, this.$store.state.loadId.lon];
        this.items = { ...this.items, ...this.$store.state.loadId };
        this.isActive = this.items.active;
        /* keys.forEach(key => {

          if (key == 'active') {
            if (this.items['active'] == true) {
              this.isActive = true;
            } else {
              this.isActive = false;
            }
          }
        }); */
        this.initialData = this.items;
        this.isLoad = true;
        this.loadId(this.$store.state.loadId.address);
        this.valueCovid();
        this.$store.dispatch('getHouses', { houseId: this.$route.params.id }).then(houses => {
          this.documents = houses[0]?.details?.documents;
        });
      }, error => {
        console.error(error);
      });
    },
    totalFunc() {
      const item = false;
      console.log('totalFunc', this.items.population)
      this.$store.dispatch('saveCitizes', {
        buildingId: this.$store.state.loadId.id,
        total: this.items.population.total || 0,
        in: this.items.population.in || 0,
        out: this.items.population.out || 0,
        notRegistred: 0
      }).then(result => {
        const r = this.$router.resolve({
          name: 'edit',
          params: { id: this.$store.state.loadId.id }
        });
        window.location.assign(r.href);
      });
    },
    formattedFiles(files) {
      const formattedFiles = [];
      console.log(files);
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      return formattedFiles;
    },
    loadDocuments(files) {
      if (!files?.length) {
        this.documents = [];
        this.isButton = false;
        return;
      }
      this.documents = this.formattedFiles(files);
      this.isButton = false;
    },
    getInfo(obj) {
      for (const field in obj) {
        if (this.items?.population[field] === obj[field]) {
          this.isButton = true;
        } else {
          this.isButton = false;
        }
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.save,
        title: this.items.address,
        item: this.items
      });
    },
    async DeleteItem(item) {
      await this.$refs.modal.openModal(DeleteModal, {
        item
      });
    },
    printDiv(divName) {
      const printContents = document.getElementById(divName).innerHTML;
      const printContents1 = document.getElementById('printableArea1').innerHTML;
      const printContents2 = document.getElementById('printableArea2').innerHTML;

      const printwin = window.open('', '', 'width=900,height=650');
      printwin.document.write(`<div><div>${
        printContents2}</div>${printContents}</div>`);

      printwin.stop();
      printwin.print();
      printwin.close();
    },
    inputCovid() {
      if (this.value != '') {
        this.value = this.value.replace(/[^\d,]/g, '');
        this.isButton = false;
      }
    },
    valueCovid() {
      let itemsProcessed = 0;

      this.$store.state.loadId.covids.forEach((item, index, array) => {
        itemsProcessed++;
        this.value += item.porch;

        if (itemsProcessed !== array.length) {
          this.value += ',';
        }
        this.isCovid = false;
      });
    },
    async loadId(result) {
      this.address = result;
    },
    active() {
      const item = {
        active: !this.isActive,
        id: this.$store.state.loadId.id
      };
      this.$store.dispatch('loadActive', item).then(result => {
        if (typeof (result.updated) !== 'undefined') {
          this.isActive = !this.isActive;
          // this.$store.dispatch('loadObjects');
        }
      });
    },
    onDragMarker(e) {
      this.isButton = false;
      this.coors = true;
      this.coordinates = e.get('target').geometry.getCoordinates();
    },
    checkHome(total, ins, out) {
      this.items.population.total = total;
      this.items.population.in = ins;
      this.items.population.out = out;
      this.isButton = false;
    },
    funcCheck() {
      this.isCovid = !this.isCovid;
      this.isButton = false;
    },
    save() {
      let item = {};
      const _this = this;
      this.loadSave = true;
      if (this.items.buildingType === 'Многоквартирный') {
        item = {
          active: {
            lat: this.coordinates[0],
            lon: this.coordinates[1],
            population: {
              total: this.items.population.total || 0,
              in: this.items.population.in || 0,
              out: this.items.population.out || 0
            }
          },
          id: this.$store.state.loadId.id
        };
      } else {
        item = {
          active: { lat: this.coordinates[0], lon: this.coordinates[1] },
          id: this.$store.state.loadId.id
        };
      }
      this.$store.dispatch('operateHouses', {
        item: {
          houseId: this.$store.state.loadId.id,
          details: {
            documents: this.documents
          },
          hidden: 0
        }
      }).then(res => {
        this.$store.dispatch('loadCoors', item).then(() => {
          const arr = this.value.split(',');
          let itemsProcessed = 0;

          this.$store.dispatch('loadCovidCancel', item).then(() => {
            if (!this.isCovid) {
              arr.forEach(items => {
                const covidItem = {
                  active: items,
                  id: this.$store.state.loadId.id
                };
                itemsProcessed++;
                if (items !== '') {
                  this.$store.dispatch('loadCovid', covidItem).then(() => {
                    _this.totalFunc();
                  });
                } else {
                  _this.totalFunc();
                }
              });
            } else {
              _this.totalFunc();
              this.value = '';
            }
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.obj-card {
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.edit {
  &__map {
    display: block;
    width: 650px;
    height: 500px;
    float: right;
    margin-bottom: 110px;
  }
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.text-covid {
  display: table;
  float: right;
  width: calc(100% - 174px)
}

::v-deep .rir-file-list__details {
  padding: 6px;
}

::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.72;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}

::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}

::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.float-left {
  float: left;
}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  margin-top: 1px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  @include reset-list;
  width: 361px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;

  opacity: 0.48;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-right: 12px;
}

@media screen and (min-width: 1120px) {
  .block-hiden {
    display: none;
  }
}

@media screen and (min-width: 10px) and (max-width: 1120px) {
  .block-hiden-too {
    display: none;
  }
  .margin-button {
    margin-top: 25px;
  }

}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
}

.icon-placeholder {
  display: inline-block; /* Строчно-блочный элемент */
  position: relative; /* Относительное позиционирование */
}

.icon-placeholder:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1000%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 190px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding-top: 4px;
  padding-left: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
